@import '../../styles/theme.scss';

$HEADER_HEIGHT: 4rem;
$SIDEBAR_WIDTH: 5rem;

.tabsHeader {
  display: flex;
  height: $HEADER_HEIGHT;
  align-items: center;
  border-bottom: 1px solid $BORDER_GREY;
}

.nav {
  color: $TEXT_GREY;
  margin: 0 1rem;
  flex: 1;
}

.navAnchor {
  height: $HEADER_HEIGHT;
  padding: 0 1rem;
  color: inherit;
  text-decoration: none;
}

.navActive {
  color: $TEXT_ACTIVE;
  font-weight: bold;
  border-bottom: 2px solid $TEXT_ACTIVE;
  padding-bottom: 1.25rem;
}

.logoWrapper {
  display: flex;
  justify-content: center;
  width: 4rem;
  padding: 0 1rem;
}

.pageHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
}

button {
  height: 2rem;
  margin: 0.5rem 0;
  padding: 0 1rem;
  background-color: black;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  color: white;
  font-weight: bold;
  outline: none;
}

.backBtn {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding-left: 10px;
}
