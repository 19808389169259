@import '../../../../styles/theme.scss';

.toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
}

.mainContainer {
  display: flex;
  justify-content: space-between;
}

.mainContainer > div {
  width: 100%;
  margin: 0 1rem;
}

.saveBtn {
  background-color: $THEME_RED;
  padding: 0 2.5rem;
  align-self: flex-start;
}

.saveBtnDisabled {
  opacity: 0.3;
}
