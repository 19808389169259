$THEME_RED: #dc3e39;

$BORDER_GREY: #cbd2e8;

$TEXT_GREY: #596076;
$TEXT_ACTIVE: #272a34;
$TEXT_DEFAULT: #272a34;
$TEXT_ERROR: $THEME_RED;

$BG_BLUEISH_GREY: #edeff6;
$BG_ERROR: #ffd5d5;
