.toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
}

button {
  height: 2rem;
  margin: 0.5rem 0.5rem;
  padding: 0 1rem;
  background-color: black;
  border: none;
  border-radius: 1rem;
  cursor: pointer;
  color: white;
  font-weight: bold;
  outline: none;
}

.manageRolesBtn {
  background-color: #e0e0e0;
  color: black;
}

.addClientBtn {
  color: white;
  background-color: #007eff;
}

.toolbarLeftPart {
  display: flex;
  align-items: center;
}

.toolbarLeftPart div {
  margin: 0 0.5rem 0 0;
}

.profileImage {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  border: solid 2px #e0e0e0;
}
