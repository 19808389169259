@import '../../../../styles/theme.scss';

.toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
}

.addNewFormBtn {
  color: white;
  background-color: #007eff;
}

.saveBtn {
  background-color: $THEME_RED;
  padding: 0 2.5rem;
  align-self: flex-start;
}

.saveBtnDisabled {
  opacity: 0.3;
}

.cancelBtn {
  border: solid 1px $THEME_RED;
  background-color: white;
  color: $THEME_RED;
  padding: 0 2.5rem;
  align-self: flex-start;
}

.assignFormsBtn {
  color: white;
  background-color: black;
}
