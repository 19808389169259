@import '/theme';

@mixin smooth-font {
  $smoothTextShadowColor: rgba(0, 0, 0, 0.01) 0 0 1px;
  font-size: 100%;
  -webkit-text-size-adjust: 100%;
  font-variant-ligatures: none;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-shadow: $smoothTextShadowColor;
}

html {
  color: $TEXT_DEFAULT;
}
body {
  margin: 0;
  font-family: Helvetica, Arial, Sans-Serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $TEXT_DEFAULT;
  @include smooth-font;
}
