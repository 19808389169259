.mainContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  flex-direction: column;
}

.pdfcontainer {
  position: relative;
  width: 75%;
  overflow: hidden;
  padding-top: 56.25%; /* 16:9 Aspect Ratio */
}

.responsiveIframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border: none;
}

.pdfAndJsonContainer {
  display: flex;
  justify-content: space-between;
}

.jsonContainer {
  padding: 1rem;
}
