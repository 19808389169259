@import '../../styles/theme';

$SIDEBAR_WIDTH: 5rem;

.pageWrapper {
  max-width: 100vw;
  display: grid;
  grid-template-columns: $SIDEBAR_WIDTH 1fr;
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.ratesNav {
  color: $TEXT_GREY;
  padding-top: 1.5rem;
}

.ratesNavAnchor {
  padding: 0 1rem;
  color: inherit;
  text-decoration: none;
}

.ratesNavActive {
  color: $TEXT_ACTIVE;
  font-weight: bold;
  border-bottom: 2px solid $TEXT_ACTIVE;
  padding-bottom: 1rem;
}
