@import '../../styles/theme';

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
  min-height: 100vh;

  input {
    margin: 0.5rem 0;
    width: 15rem;
    height: 2.5rem;
    padding: 0.5rem;
    box-sizing: border-box;

    border: 1px solid $BORDER_GREY;
    border-radius: 5px;
  }
}

.signupBtn {
  width: 15rem;
  height: 2.5rem;
  margin: 0.5rem 0;
  padding: 0;
  background-color: $THEME_RED;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  color: white;
}

.cancelLink {
  color: inherit;
  text-decoration: none;
}
