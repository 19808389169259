@import '../../../../styles/theme.scss';

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem 2rem;
  flex-direction: column;
}

.inputContainer {
  margin: 0.5rem 0;
  width: 350px;
}

.saveBtn {
  background-color: $THEME_RED;
  padding: 0 2.5rem;
  align-self: flex-start;
}

.saveBtnDisabled {
  opacity: 0.3;
}

.dropdown {
  width: 100%;
}

.avatarWrapper {
  height: 100px;
  display: flex;
  justify-content: center;
}

.uploadIcon {
  width: 100px;
  height: 100px;
  position: relative;
  cursor: pointer;
}

.fileUpload {
  width: 100px;
  height: 100px;
  position: absolute;
  opacity: 0;
}

.profileImage {
  width: 100px;
  height: 100px;
  border-radius: 100%;
  border: solid 2px #e0e0e0;
}
