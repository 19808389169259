@import '../../styles/theme';

$SIDEBAR_WIDTH: 5rem;
$ICON_COLOR: #596076;

.toolbar {
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;

  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.08);
}
