@import '../../styles/theme';

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
  min-height: 100vh;

  input {
    margin: 0.5rem 0;
    width: 15rem;
    height: 2.5rem;
    padding: 0.5rem;
    box-sizing: border-box;
    outline: 0;
    border: 1px solid $BORDER_GREY;
    border-radius: 5px;
  }
}

.loginBtn {
  width: 15rem;
  height: 2.5rem;
  margin: 0.5rem 0;
  padding: 0;
  background-color: $THEME_RED;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  color: white;
  font-weight: bold;
  outline: none;
}

.googleLoginBtn {
  background-color: white;
  color: $TEXT_GREY;
  border: 1px solid $BORDER_GREY;
  display: flex;
  align-items: center;
}

.greyText {
  color: inherit;
  text-decoration: none;
  color: $TEXT_GREY;
}

.textBtn {
  color: $THEME_RED;
  text-decoration: none;
  font-size: 0.8rem;
  background: white;
  height: auto;
  font-weight: normal;
  text-align: start;
}

.googleIconWrapper {
  position: relative;
  height: 100%;
  min-width: 2.75rem;
  margin-right: 10px;
  border: solid 1px $BORDER_GREY;
  border-width: 0 1px 0 0;
}

.googleIcon {
  color: $THEME_RED;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.notificationBox {
  padding: 0 10px;
  background: $TEXT_ERROR;
  color: $BG_ERROR;
  font-weight: bold;
  font-size: 0.8rem;
  border-radius: 5px;
}

.logoContainer {
  min-height: 10rem;
}
