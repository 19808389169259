@import '../../../../styles/theme.scss';

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem 2rem;
  flex-direction: column;
}

.container div {
  margin: 0.5rem 0;
  width: 350px;
}

.saveBtn {
  background-color: $THEME_RED;
  padding: 0 2.5rem;
  align-self: flex-start;
}

.sabeBtnDisabled {
  opacity: 0.3;
}

.dropdown {
  width: 100%;
}
