@import '../../styles/theme';

$SIDEBAR_WIDTH: 5rem;
$ICON_COLOR: #596076;

.sidebar {
  width: $SIDEBAR_WIDTH;
  // height: calc(100vh - 4rem);
  background: $BG_BLUEISH_GREY;
  border-right: 1px solid $BORDER_GREY;
  display: flex;
  flex-direction: column;
  align-items: center;

  padding-top: 1rem;
}

.iconWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3.5rem;

  font-size: 1.5rem;
  color: $ICON_COLOR;
}
