@import '../../../../styles/theme.scss';

.mainContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 1rem;
  flex-direction: column;
}

.sigContainer {
  width: 80%;
  height: 80%;
  margin: 0 auto;
  background-color: #e0e0e0;
}

.sigPad {
  width: 100%;
  height: 100%;
}

.saveBtn {
  background-color: $THEME_RED;
  padding: 0 2.5rem;
}

.clearSignatureBtn {
  border: solid 1px $THEME_RED;
  padding: 0 2.5rem;
  background-color: #fff;
  color: $THEME_RED;
}

.saveBtnDisabled {
  opacity: 0.3;
}

.bottomContainer {
  display: flex;
  flex-direction: column;
  margin: 1rem 0 0 0;
}
