@import '../../styles/theme';

$HEADER_HEIGHT: 4rem;
$SIDEBAR_WIDTH: 5rem;

.header {
  display: flex;
  height: $HEADER_HEIGHT;
  align-items: center;
  border-bottom: 1px solid $BORDER_GREY;
}

.burgerWrapper {
  display: flex;
  width: $SIDEBAR_WIDTH;
  justify-content: center;
  font-size: 1.5rem;
  color: $TEXT_DEFAULT;
}

.logoWrapper {
  display: flex;
  justify-content: center;
  width: 4rem;
}

.nav {
  color: $TEXT_GREY;
  margin: 0 1rem;
  flex: 1;
}

.navAnchor {
  height: $HEADER_HEIGHT;
  padding: 0 1rem;
  color: inherit;
  text-decoration: none;
}

.navActive {
  color: $TEXT_ACTIVE;
  font-weight: bold;
  border-bottom: 2px solid $TEXT_ACTIVE;
  padding-bottom: 1.25rem;
}

.userSection {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 2rem;
  color: $TEXT_GREY;
}

.divider {
  border-right: 1px solid $TEXT_GREY;
  height: 1rem;
  width: 1rem;
  margin: 0 1rem 0 0;
}

.logout {
  cursor: pointer;
}

.settingOptionNavLink {
  text-decoration: none;
  color: black;
  border: solid 1px #000;
}

.settingsMenuNavAnchor {
  color: inherit;
  text-decoration: none;
}

.settingsIcon {
  cursor: pointer;
}
